<!--
 * @Description: 
 * @Author: xiawenlong
 * @Date: 2021-04-20 10:56:53
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-05-19 14:38:43
-->
<template>
  <div class="teacher-list">
    <table-list
      class="tableList"
      title="课程列表"
      :loading="loading"
      :search-form="searchForm"
      :data="offlineCoursesList"
      :columns="columns(this)"
      :operates="operates(this)"
      :btns="btns(this)"
      :options="{ selection: true }"
      :pager="pager"
      @search="onSearch"
      @selectionChange="handleSelectionChange"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></table-list>
  </div>
</template>
<script>
// 顶部按钮
const btns = _this => [
  {
    label: '创建课程',
    method: _this.handleAdd,
  },
  {
    label: '删除',
    method: _this.handleDelete,
    type: 'danger',
  },
]
// 搜索框配置
const searchForm = [
  {
    label: '课程名称',
    prop: 'teacherName',
  },
  {
    label: '可用状态',
    type: 'select',
    prop: 'isOpen',
    children: [
      { value: true, label: '可用' },
      { value: false, label: '不可用' },
    ],
  },
  {
    label: '上传时间',
    type: 'picker',
    prop: 'dateTime',
  },
]
// 表格列配置
const columns = _this => [
  {
    prop: 'coursePic',
    label: '课程封面',
    render: (h, { row }) => {
      return [
        row.coursePic
          ? h('img', {
              domProps: { src: row.coursePic },
              style: 'height:80px;width:80px;',
            })
          : null,
      ]
    },
  },
  {
    prop: 'courseName',
    label: '课程名称',
  },
  {
    prop: 'courseDesc',
    label: '课程简介',
    width: 500,
    render(h, { row }) {
      return h(
        'div',
        {
          class: 'courseDesc',
        },
        row.courseDesc,
      )
    },
  },
  {
    prop: 'courseDuration',
    label: '课程时长',
    formatter: row => {
      return row.courseDuration ? row.courseDuration + '天' : ''
    },
  },
  {
    prop: 'teacherName',
    label: '任课老师',
  },
  {
    label: '可用状态',
    render: (h, { row }) => {
      return [
        h('el-switch', {
          props: { value: row.status, activeValue: true, inactiveValue: false },
          on: {
            change: async v => {
              await _this.handleOpen(v, row)
            },
          },
        }),
      ]
    },
  },
  {
    prop: 'createTime',
    label: '创建时间',
  },
]
// 表格操作按钮配置
const operates = _this => [
  {
    label: '编辑',
    method: _this.handleEdit,
  },
]
import TableList from '@/components/TableList'
import { deleteBatch, changeStatus, offlineCourses } from '@/api/teacher'
import to from 'await-to'
export default {
  components: {
    TableList,
  },
  data() {
    return {
      btns,
      searchForm,
      columns,
      operates,
      loading: false,
      offlineCoursesList: [],
      selectTeachers: [],
      queryParams: {},
      pager: {
        total: 0,
        size: 10,
        current: 1,
        teacherId: this.$route.query.teacherId,
      },
      teacherName: this.$route.query.teacherName,
    }
  },
  mounted() {
    this.offlineCoursesData()
  },
  methods: {
    async offlineCoursesData() {
      if (this.loading) return
      const { current, size, teacherId } = this.pager
      this.loading = true
      const [res, err] = await to(offlineCourses({ current, size, teacherId, ...this.queryParams }))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.offlineCoursesList = res.data.list
      this.pager.total = res.data.total
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      if (queryParams.dateTime) {
        queryParams.beginTime = queryParams.dateTime[0]
        queryParams.endTime = queryParams.dateTime[1]
      } else {
        queryParams.beginTime = ''
        queryParams.endTime = ''
      }
      this.queryParams = queryParams
      this.offlineCoursesData()
    },
    //设置对外开放
    async handleOpen(v, row) {
      const [, err] = await to(
        changeStatus({ offlineCourseId: row.offlineCourseId, status: row.status }),
      )
      if (err) return this.$message.warning(err.msg)
      this.$message.success('成功')
      this.offlineCoursesData()
    },
    //创建课程
    handleAdd() {
      this.$router.push({
        path: '/teacher/createdOfflineCourses',
        query: { teacherId: this.pager.teacherId, teacherName: this.teacherName },
      })
    },
    // 删除
    handleDelete() {
      if (!this.selectTeachers.length) return this.$message.warning('请先选择要删除的讲师')
      let offlineCourseIds = []
      this.selectTeachers.map(item => offlineCourseIds.push(item.offlineCourseId))
      this.$confirm('确认删除讲师吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const [, err] = await to(deleteBatch({ offlineCourseIds }))
        if (err) return this.$message.warning(err.msg)
        this.$message.success('删除成功')
        this.offlineCoursesData()
      })
    },
    // 编辑
    handleEdit(row) {
      this.$router.push({
        path: '/teacher/eidtOfflineCourses',
        query: {
          teacherId: row.teacherId,
          teacherName: row.teacherName,
          offlineCourseId: row.offlineCourseId,
        },
      })
    },
    // 多选
    handleSelectionChange(val) {
      this.selectTeachers = val
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.offlineCoursesData()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.offlineCoursesData()
    },
  },
}
</script>
<style lang="scss" scoped>
.teacher-list {
  tr {
    &:last-of-type {
      width: 300px;
    }
  }
  ::v-deep.operate-group {
    // width: 50%;
    // float: left;
    span {
      // display: block;
      margin: 0 3px;
    }
  }
  .tableList ::v-deep .el-table tr .courseDesc {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; //控制行数
    overflow: hidden;
    white-space: pre-line;
  }
}
</style>
